import React, { Component, useState, useEffect } from "react";
import Header1 from "./../components/header1/header1";
import Paragraph from "../components/paragraph/paragraph";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Image from "react-bootstrap/Image";
import { useFormik } from "formik";
import FireClock from "./../components/fireClock/fireClock";
import Button from "./../components/button/button";
import { Link, navigate } from "gatsby";
import ContactForm from "./../components/contactForm/contactForm";

import Footer from "./../components/footer/footer";
import Navbar from "../components/navbar/navbar";
import Carousel from "react-elastic-carousel";
import { UrlConditionItem } from "survey-react";
import { ReactTypeformEmbed } from "react-typeform-embed";

var headerImage = require("./../assets/homeSmall.jpg");
var source = require("./../assets/lineArt/sourcemap.png");
var replant = require("./../assets/lineArt/crowdfund.png");
var project = require("./../assets/replant.jpg");
var save = require("./../assets/lineArt/healthyForest.png");

const padding = 40;

export default function Results() {
  return (
    <div>
      <Navbar />
      <div style={{ width: "100%", height: "80vh" }}>
        <img
          src={headerImage}
          style={{
            height: "80vh",
            width: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <div style={{ position: "absolute", height: "80vh", width: "100%" }}>
          <Grid
            container
            style={{
              paddingLeft: padding,
            }}
          >
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
              style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Header1
                  style={{
                    color: "white",
                    fontSize: "5vh",
                  }}
                >
                  Go Carbon Neutral with carbon down by protecting and restoring america's forest
                </Header1>
                <Button
                  text={"View Projects"}
                  onClick={() => navigate("/projects")}
                  width={"50%"}
                  marginTop={20}
                />
              </div>
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
          </Grid>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Grid container>
          <Grid container style={{ padding: padding }}>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item>
                  <Header1 fontSize={30}>How We Preserve Forests</Header1>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Grid container style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Image
                        src={source}
                        width={"80%"}
                        style={{ borderRadius: 10 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ paddingRight: padding }}
                    >
                      <Paragraph style={{ fontSize: 30 }}>
                        Source Projects
                      </Paragraph>
                      <Paragraph>
                        By collaborating with organizations in your area, we
                        identify forests that are in need of protection and
                        restoration. We choose this land based on its potential
                        to reduce the impact your emissions have on climate
                        change.
                      </Paragraph>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{ paddingTop: 20, paddingBottom: 20 }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Image
                        src={replant}
                        width={"80%"}
                        style={{ borderRadius: 10 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ paddingRight: padding }}
                    >
                      <Paragraph style={{ fontSize: 30 }}>
                        Fund Projects
                      </Paragraph>
                      <Paragraph>
                        We connect your dollars to projects in your community so
                        that you can protect and restore the ecosystems you
                        love, while also removing carbon from the atmosphere.
                      </Paragraph>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{ paddingTop: 20, paddingBottom: 20 }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Image
                        src={save}
                        width={"80%"}
                        style={{ borderRadius: 10 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ paddingRight: padding }}
                    >
                      <Paragraph style={{ fontSize: 30 }}>
                        Protect + Restore Land
                      </Paragraph>
                      <Paragraph>
                        We fully restore the land near you. Your dollars fund
                        land protection, restoration, and third-party
                        verification to ensure the long term validity and
                        effectiveness of the project.
                      </Paragraph>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />

          <Grid
            container
            style={{ padding: padding, backgroundColor: "#A4C2A8" }}
          >
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <Header1>Who We Work With</Header1>
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />

            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              style={{ paddingTop: 40 }}
            >
              <Hidden smDown>
                <Carousel
                  itemsToShow={3}
                  showArrows={false}
                  enableAutoPlay={true}
                  autoPlaySpeed={5000}
                  pagination={false}
                >
                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/mendocino.gif")}
                  />

                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/bigSur.png")}
                  />
                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/aff.png")}
                  />
                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/X.png")}
                  />
                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/TomKat.jpg")}
                  />
                </Carousel>
              </Hidden>
              <Hidden mdUp>
                <Carousel
                  itemsToShow={1}
                  showArrows={false}
                  enableAutoPlay={true}
                  autoPlaySpeed={5000}
                  pagination={false}
                >
                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/mendocino.gif")}
                  />

                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/bigSur.png")}
                  />
                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/aff.png")}
                  />
                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/X.png")}
                  />
                  <Image
                    style={{ height: 200 }}
                    src={require("./../assets/partners/TomKat.jpg")}
                  />
                </Carousel>
              </Hidden>
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
          </Grid>

          <Grid container style={{ padding: padding }}>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ paddingRight: padding }}
                    >
                      <Header1 style={{ color: "#22422b" }}>
                        State Of Our Forests
                      </Header1>
                      <Paragraph style={{ fontSize: 18 }}>
                        The United States has lost 100s of millions of acres of
                        forest since 1980, mostly due to catastrophic wildfires
                        and conversion of forest into suburban areas. To prevent
                        the permanent loss of our national treasures, action
                        must be taken now.
                      </Paragraph>
                      <img
                        src={require("./../assets/burnMap.png")}
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <Paragraph style={{ fontSize: 18 }}>
                        Carbon Down uses carbon offset funding to protect and
                        restore American forests while neutralizing your carbon
                        emissions, which contribute to climate change.
                      </Paragraph>
                    </Grid>
                  </Grid>
                </Grid>
                <Hidden smDown>
                  <Grid
                    item
                    xs={0}
                    sm={0}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ paddingTop: 20, paddingBottom: 20 }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <img
                          src={require("./../assets/hiking.jpg")}
                          width={"127%"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              {/* <ContactForm /> */}
              {/* <ReactTypeformEmbed style={{height:'10vhh', position:'absolute'}} popup={false} url="https://szjbjhzaiaq.typeform.com/to/DjAJNiJN" /> */}
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
