import React, { Component, useState, useEffect } from "react"
import { useFormik } from "formik"
import Button from "./../button/button"
import firebase from "gatsby-plugin-firebase"
import { navigate } from "gatsby"
import Header1 from "./../header1/header1"

class FireClock extends React.Component {
    constructor(props) {
      super(props)
      this.state = { date: new Date() }
    }
  
    componentDidMount() {
      this.timerID = setInterval(() => this.tick(), 1)
    }
  
    componentWillUnmount() {
      clearInterval(this.timerID)
    }
  
    tick() {
      this.setState({
        date: new Date(),
      })
    }
  
    render() {
      const ISOStringDate = this.state.date.getTime()
      return (
        <div>
          <h1>Acres Of Forest Burnt This Year</h1>
          <h2>
            {Math.round(
              100 * (9539554 + ((ISOStringDate - 1607648543334) / 1000) * 0.25)
            ) / 100}
          </h2>
        </div>
      )
    }
  }

  export default FireClock;